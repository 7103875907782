.background-container {
    width: 100%;
    height: 80vh;
    /* Adjust height as needed */
    background-image: url('../../assets/icons/carInsuranceBgImage.svg');
    /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: rgba(0, 0, 0, 0.5);  */
    background-blend-mode: multiply;
    /* Blend the gradient with the background image */
    /* border: 1px solid red; */
    padding-bottom: 20px;
}

.background-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    /* border: 1px solid red; */
    background: linear-gradient(180deg, rgba(12, 51, 92, 0.6) 15.4%, rgba(3, 166, 124, 0.6) 54.75%);
}

.l1 {
    /* border: 1px solid red; */

    font-family: Poppins;
    font-size: 38px;
    font-weight: 300;
    line-height: 57px;
    line-height: 50px;
    letter-spacing: 0.002em;
    text-align: left;
    margin: 0;
    color: #fff
}

.l2 {
    font-family: Poppins;
    font-size: 65px;
    font-weight: 900;
    line-height: 97.5px;
    line-height: 60px;
    letter-spacing: 0.002em;
    text-align: left;
    margin: 0;
    color: #fff;
    /* border: 1px solid red; */


}

.l3 {
    /* border: 1px solid red; */

    font-family: Poppins;
    font-size: 38px;
    font-weight: 300;
    line-height: 50.16px;
    line-height: 50px;
    letter-spacing: 0.002em;
    text-align: left;
    margin: 0;
    color: #fff
}

.span {
    /* border: 1px solid red; */

    font-family: Poppins;
    font-size: 38px;
    font-weight: 500;
    line-height: 50.16px;
    letter-spacing: 0.002em;
    text-align: left;
    margin: 0;

}


@media screen and (max-width: 768px) {
    .background-container {
        height: 95vh;
    }
    
    .background-container::after {
        height: 100vh;
    }
    .l1 {
        font-size: 24px;
        line-height: 32px;
    }

    .l2 {
        font-size: 40px;
    }

    .l3 {
        font-size: 24px;
        line-height: 32px;
    }

    .span {
        font-size: 24px;
        line-height: 32px;
    }
}

@media screen and (min-width: 769px) and (max-width:992px) {
    .l1 {
        font-size: 32px;
    }

    .l2 {
        font-size: 56px;
    }

    .l3 {
        font-size: 32px;
    }

    .span {
        font-size: 32px;
    }
}

@media screen and (min-width: 993px) and (max-width:1199px) {
    .l1 {
        font-size: 32px;
    }

    .l2 {
        font-size: 56px;
    }

    .l3 {
        font-size: 32px;
    }

    .span {
        font-size: 32px;
    }
}