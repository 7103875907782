.mobile-banner {
  background: linear-gradient(
    101.34deg,
    #02a65f 13.71%,
    rgba(3, 163, 175, 0) 166.42%
  );
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mob-content {
  color: #fff;
}
