.nav-option {
  width: auto;
  padding: 10px;
  position: relative;
}

.nav-option:hover::after {
  content: "";
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  animation-name: underline;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.dropdown-nav {
  font-weight: 400;
}
.logo-container {
  max-width: 150px;
}
.logo-container > img {
  object-fit: contain;
  max-width: 100%;
  min-width: 120px;
}
.dropdown-nav::after {
  /* content: url("../../assets/icons/dropdown_icon.svg"); */
  margin-left: 6px;
  display: inline-block;
  margin-bottom: 2px;
}
.humburger-btn.p-button {
  padding: 0.5rem;
}
.humburger-btn.p-button:focus {
  box-shadow: none;
}
.humburger-btn.p-button:active {
  box-shadow: none;
}
.humburger-btn.p-button.p-button-text:not(:disabled):active {
  background-color: #fff;
  color: #000;
  border-color: transparent;
}
.header-link {
  color: #000;
  text-decoration: none;
}
.nav-highlight {
  font-weight: 600;
}

@keyframes underline {
  0% {
    width: 0%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

/* Mobile dropdown menu styles */
.mobile-dropdown-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transform: translateY(-100%); /* Initially hidden (above screen) */
  transition: transform 0.3s ease-in-out; /* Smooth transition */

}

.mobile-dropdown-menu.visible {
  transform: translateY(0); /* Slide down to visible position */
}

.mobile-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-dropdown-menu .nav-option {
  margin: 10px 0;
  cursor: pointer;
}

.mobile-dropdown-menu .header-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.mobile-dropdown-menu .header-link:hover {
  background-color: #f0f0f0;
}

.mobile-dropdown-menu .nav-highlight {
  font-weight: bold;
  color: #007bff;
}

/* Logo and close button in the sidebar */
.mobile-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 8px 16px;
}

.insuright-logo-sidebar {
  width: 120px; /* Adjust logo size */
  height: auto;
}

.close-btn {
  font-size: 24px;
  padding: 0;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
}

/* Hamburger menu button */
.humburger-btn {
  font-size: 24px;
}

/* Add transition effects for the close icon button */
.close-btn:hover {
  background-color: #f0f0f0;
}
