.header{
    font-family: Lato;
font-size: 42px;
font-weight: 600;
line-height: 30px;
text-align: left;
margin: 16px;

}

@media screen and (max-width: 768px) {
    .header{
    font-size: 24px;
    
    }
}

@media screen and (min-width: 769px) and (max-width:992px) {
   
}

@media screen and (min-width: 993px) and (max-width:1199px) {
    
}