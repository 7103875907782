.content {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #000000;
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
    .content{
        font-size: 12px;
        line-height: 24px;
    }
    }
    @media screen and (min-width: 769px) and (max-width:992px) {
        .content{
            font-size: 14px;
            line-height: 24px;
        }
    }