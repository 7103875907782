.container{
    padding: 30px 100px;
}
.TermsAndCondition{
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0px;
    text-align: center;
}
.heading{
    font-size: 16px;
    font-weight: 300;
    margin: 20px 0px;
}
.link{
    text-decoration: underline;
    font-size: 16px;
    color: blue;
    cursor: pointer;
    font-weight: 300;
}
@media screen and (max-width: 768px) {
    .container{
        padding: 30px 20px;
    }
    .TermsAndCondition{
        font-size: 14px;
    }
    .heading{
        font-size: 12px;
    }
    .link{
        font-size: 12px;
    }
    }
    @media screen and (min-width: 769px) and (max-width:992px) {
        .container{
            padding: 30px 60px;
        }
        .TermsAndCondition{
            font-size: 18px;
        }
        .heading{
            font-size: 16px;
        }
        .link{
            font-size: 16px;
        }
    }