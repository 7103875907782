.subHeading {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 300;
    line-height: 28.56px;
    margin: 0;
    color: #2D2D2D;


}

.heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.7px;
    color: #2D2D2D;
    margin: 0;

}
@media screen and (max-width: 768px) {
    .subHeading {
        font-size: 14px;
    }
    
    .heading {
        font-size: 16px;
        line-height: 28.56px;
    }
}

@media screen and (min-width: 769px) and (max-width:992px) {
    .subHeading {
        font-size: 20px;
    }
    
    .heading {
        font-size: 24px;
    }
}

@media screen and (min-width: 993px) and (max-width:1199px) {
    .subHeading {
        font-size: 20px;
    }
    
    .heading {
        font-size: 24px;
    }
}