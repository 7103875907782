.App-header {
  background: #fff;
  box-shadow: -8px 12px 15px 2px #00000008;
}
.banner {
  height: 70vh;
  background: linear-gradient(
    180deg,
    rgba(12, 51, 92, 0.6) 15.4%,
    rgba(3, 166, 124, 0.6) 54.75%
  );
}
.bg-img {
  background-image: url("/public/bg_banner1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.login-button.p-button {
  background: linear-gradient(180deg, #06a4b4 24.8%, #01a65a 100%);
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.25rem;
}
.login-button.p-button:focus {
  box-shadow: none;
}
.nav-button.p-button {
  color: #000;
  padding: 0.5rem 1rem;
}
.nav-button.p-button.p-button-text:not(:disabled):active {
  background: none;
}
.nav-button.p-button:focus {
  box-shadow: none;
}
.banner-info-continer {
  height: 70vh;
}
.banner-info {
  color: #fff;
}

.servies-nav {
  background-color: #fff;
  min-height: 100px;

  bottom: -70px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000014;
  overflow-x: auto;
}
.service-card {
  min-width: 130px;
  border-radius: 8px;
  cursor: pointer;
}
/* .service-card.active {
  background: rgb(3, 165, 87, 20%);
} */
.service-card.active,
.service-card:hover {
  background: rgb(3, 165, 87, 20%);
}
.customer-benifits {
  overflow-x: auto;
  height: 200px;
  overflow-y: hidden;
}
.customer-benifit-card {
  min-width: 200px;
  position: relative;
}
.card-info-cont {
  top: 70%;
  color: #fff;
  height: 200px;
  overflow-y: hidden;
}
.card-desc {
  display: none;
  text-align: center;
  font-size: 0.875rem;
}
.customer-benifit-card:hover .card-info-cont > .card-desc {
  display: block;
}
.customer-benifit-card:hover .card-info-cont {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.customer-review-card {
  box-shadow: 0px 0px 50px 0px #0000001a;
  min-width: 200px;
  width: 24%;
  max-width: 320px;
  height: auto;
  border-radius: 27px;
}
.mob-app-view {
  min-width: 300px;
}
.customer-message {
  color: rgb(10, 10, 10, 50%);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.partner-container {
  background: #edf5fc;
}
.ins_partner {
  width: 180px;
}
.footer {
  min-height: 100px;
  background: linear-gradient(180deg, #06335d 0%, #01080f 100%);
  color: #fff;
}
.footer-abs {
  background: linear-gradient(180deg, #02a2b5 0%, #03a456 100%);
  min-height: 100px;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000014;
  overflow-x: auto;
}

.white-text {
  color: #fff;
}
.green-text {
  color: #03a67c;
}
a {
  color: #fff;
}
.copyright-container {
  border-top: 0.5px solid #545454;
}

@media (max-width: 600px) {
  .banner {
    height: 50vh;
  }
  .banner-info-continer {
    height: 50vh;
  }
}

@keyframes slideUp {
  from {
    top: 70%;
  }
  to {
    top: 0;
  }
}
