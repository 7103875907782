.listContainer {
    padding: 16,
}

.listItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.bullet {
    font-size: 1.25rem;
    line-height: 30px;
    color: #03a67c;
    margin-right: 8px;
}

.itemText {
    font-family: Lato;
    font-size: 1.25rem;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
    color: #000000;
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;  /* Ensure normal white-space handling */
}

@media screen and (max-width: 768px) {
    .itemText{
        font-size: 12px;
        line-height: 24px;

    }
    .bullet {
        font-size: 12px;
        line-height: 24px;

    }
    }
    @media screen and (min-width: 769px) and (max-width:992px) {
        .itemText{
            font-size: 14px;
            line-height: 24px;

        }
        .bullet {
            font-size: 14px;
            line-height: 24px;

        }
    }