.header {
    font-family: Lato;
    font-size: 42px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin: 0;
    color: #fff
}

.input {
    border: 1px solid #FAFAFA !important;
    border-radius: 4px !important;
    width: 100%;
    align-self: center;
}

.buttonContainer {
    background: #000 !important;
    width: 100%;
    /* padding: 4px ; */
    cursor: pointer;
    border-radius: 4px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.buttonText {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    align-self: center;
    margin: 0;
    background: #000 !important;
    border: 0 !important;
    width: 100%;
    /* text-align: left; */

}

@media screen and (max-width: 768px) {
    .header {
        font-size: 32px;
        
    }
    .input {
        width: 100%;
    }
    .buttonContainer {
        width: 100%;
    
    }
    }
    @media screen and (min-width: 769px) and (max-width:992px) {
        .header {
            font-size: 36px;
            
        }
        .input {
            width: 90%;
        }
        .buttonContainer {
            width: 90%;
        
        }
    }
    @media screen and (min-width: 993px) and (max-width:1199px) {
        .header {
            font-size: 36px;
            
        }
        .input {
            width: 90%;
        }
        .buttonContainer {
            width: 90%;
        
        }
    }