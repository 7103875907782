.basic{
    font-size: 16px;
    text-align: start;
    margin: 10px 0px;
    line-height: 140%;
    /* text-wrap: wrap; */
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal wrap;  
}

.heading{
    font-weight: 700;
   
}
.subHeading{
    font-weight: 500;
   
}

.paragraph{
    font-weight: 400;
   
}


.termsHeading{
    text-decoration: underline;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
.basic{
    font-size: 12px;
}
}
@media screen and (min-width: 769px) and (max-width:992px) {
    .basic{
        font-size: 14px;
    }
}