.customTable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .tableHeader {
    background-color: #f4f4f4;
    text-align: left;
    padding: 10px;
    border: 1px solid #dddddd;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }
  
  .tableCell {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    font-family: Lato;
    line-height: 140%;
  }
  
  @media screen and (max-width: 768px) {
    .tableHeader {
      font-size: 14px;
    }
    .tableCell{
        font-size: 12px;
    }
  }
  @media screen and (min-width: 769px) and (max-width:992px) {
    .tableHeader{
        font-size: 14px;
    }
}
  