.overflow-image {
  /* bottom: -100px; */
  left: 0;
  right: 0;
  top: 50%;
  margin: auto;
}
.img-container {
  border-radius: 1.4rem;
  overflow: hidden;
}
