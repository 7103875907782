.custom-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .custom-list-item {
    font-size: 16px;
    text-align: start;
    margin: 8px ;
    line-height: 140%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal wrap;
  }
  
  
  @media screen and (max-width: 768px) {
    .custom-list-item {
      font-size: 12px;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 992px) {
    .custom-list-item {
      font-size: 14px;
    }
  }
  